@import  "./vars";

@mixin create-cols($cols) {
  &.Col-#{$cols}{
    // width: (($cols / 12) * 100%);
    flex: 0 0  (($cols / 12) * 100%);
    max-width:  (($cols / 12) * 100%);
  }  
}
@mixin create-cols-size($cols, $size) {
  &.Col-#{$size}-#{$cols}{
    // width: (($cols / 12) * 100%);
    flex: 0 0  (($cols / 12) * 100%);
    max-width:  (($cols / 12) * 100%);
  }  
}

html {
  scroll-behavior: smooth;
  // to stop extra body size for some reason
  padding-bottom: 2rem;
  // background-color: white;
}

html::-webkit-scrollbar,
aside::-webkit-scrollbar,
*::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  background-color: transparent;
}

html::-webkit-scrollbar-thumb,
aside::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb
{
  border-radius: 4px;
  background-color: rgba(55, 71, 79, .3);
}
html::-webkit-scrollbar-thumb:hover,
aside::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover
{
  background-color: rgba(55, 71, 79, .7);
}

html.scroll-thumb-on-hover::-webkit-scrollbar-thumb,
aside.scroll-thumb-on-hover::-webkit-scrollbar-thumb,
*.scroll-thumb-on-hover::-webkit-scrollbar-thumb
{
  background-color: transparent;
}


select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1.2rem;
}

.AC-App--Has-Widget {
  // padding-bottom: 6.4rem;
  .Polaris-Frame__Content {
    // padding-bottom: 6.4rem;
  }
}

.Polaris-Sheet__Container {
  z-index: 999999;
}

.Text-Center {
  text-align: center;
}
.AC-Container {
  width: 100%;
  padding: $grid-padding;
  margin-right: auto;
  margin-left: auto;
  
  &.Full-Height {
    height: 100%;
  }
}


.AC-Row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  &.MA-0 {
    margin: 0;
  }

  &.Wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  &.Align-Center {
    align-content: center;
    align-items: center;
  }
  &.Justify-Center {
    justify-content: center;
    justify-items: center;
  }
  &.Full-Height {
    height: 100%;
  }
}

.AC-Col {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding: $grid-padding;
  z-index: 1;

  &.PA-0 {
    padding: 0
  }

  &.Shrink {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  &.Center-Y {
    align-items: center;
    align-content: center;
  }


  &.Center-X {
    justify-items: center;
    justify-content: center;
  }

  &.Flex {
    display: flex;
  }

  @include create-cols(1);
  @include create-cols(2);
  @include create-cols(3);
  @include create-cols(4);
  @include create-cols(5);
  @include create-cols(6);
  @include create-cols(7);
  @include create-cols(8);
  @include create-cols(9);
  @include create-cols(10);
  @include create-cols(11);
  @include create-cols(12);

  // @media (min-width: 644px) {
  //   @include create-cols-size(1, S);
  //   @include create-cols-size(2, S);
  //   @include create-cols-size(3, S);
  //   @include create-cols-size(4, S);
  //   @include create-cols-size(5, S);
  //   @include create-cols-size(6, S);
  //   @include create-cols-size(7, S);
  //   @include create-cols-size(8, S);
  //   @include create-cols-size(9, S);
  //   @include create-cols-size(10, S);
  //   @include create-cols-size(11, S);
  //   @include create-cols-size(12, S);
  // }
  
  @media (min-width: 645px) {
    @include create-cols-size(1, M);
    @include create-cols-size(2, M);
    @include create-cols-size(3, M);
    @include create-cols-size(4, M);
    @include create-cols-size(5, M);
    @include create-cols-size(6, M);
    @include create-cols-size(7, M);
    @include create-cols-size(8, M);
    @include create-cols-size(9, M);
    @include create-cols-size(10, M);
    @include create-cols-size(11, M);
    @include create-cols-size(12, M);
  }
  
  @media (min-width: 960px) {
    @include create-cols-size(1, L);
    @include create-cols-size(2, L);
    @include create-cols-size(3, L);
    @include create-cols-size(4, L);
    @include create-cols-size(5, L);
    @include create-cols-size(6, L);
    @include create-cols-size(7, L);
    @include create-cols-size(8, L);
    @include create-cols-size(9, L);
    @include create-cols-size(10, L);
    @include create-cols-size(11, L);
    @include create-cols-size(12, L);
  }
  
}